import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import ProjectCard from "./ProjectCard.js";
import Section from "./Section.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Projects.scss";
import imgOne from "../assets/images/spiritOfWicks.png";
import imgTwo from "../assets/images/carstro.png";
import imgThree from "../assets/images/myPortfolio.png";
import imgFour from "../assets/images/familySearch.png";

const projects = [
  {
    id: 1,
    title: "THE SPIRIT OF WICKS",
    description:
      "Developed a fully responsive site using Wix. Shoot, selected, and edited each photo; created mockups using Photoshop.",
    backgroundImage: imgOne,
    caseStudyLink: "https://example.com/spirit-of-wicks-case-study",
    figmaLink: "https://www.thespiritofwicks.com/",
  },
  {
    id: 2,
    title: "carstro",
    description:
      "Co-designed & developed. Solely responsible for logo, video, and body copy. Removed backgrounds from, and optimized, car images. Design Tools: Figma, Photoshop, Premiere Pro, Developing Tools: ReactJS, CSS3. Fully responsive.",
    backgroundImage: imgTwo,
    caseStudyLink: "https://example.com/spirit-of-wicks-case-study",
    figmaLink: "https://carstro-15495.web.app/",
  },
  {
    id: 3,
    title: "my portfolio",
    description:
      "Designed & developed from scratch. All visuals created. Fully responsive. Tools used: Figma, Photoshop, ReactJS, SCSS.",
    backgroundImage: imgThree,
    caseStudyLink: "https://example.com/spirit-of-wicks-case-study",
    figmaLink: "https://www.figma.com/file/spirit-of-wicks",
  },
  {
    id: 4,
    title: "familysearch®",
    description:
      "Co-designed & developed. Solely responsible for logo, video, and body copy. Removed backgrounds from, and optimized, car images. Design Tools: Figma, Photoshop, Premiere Pro, Developing Tools: ReactJS, CSS3. Fully responsive.",
    backgroundImage: imgFour,
    caseStudyLink: "https://example.com/spirit-of-wicks-case-study",
    figmaLink: "https://www.familysearch.org/es/latam/merecen-ser-recordados",
  },
];

const PortfolioProjects = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Disable browser's swipe-back navigation
  useEffect(() => {
    const preventNavigation = (e) => {
      if (e.deltaX > 50 || e.deltaX < -50) {
        e.preventDefault();
      }
    };

    window.addEventListener("wheel", preventNavigation);

    return () => {
      window.removeEventListener("wheel", preventNavigation);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500, // Adjusted for smooth transitions
    slidesToShow: 2,
    slidesToScroll: 2,
    swipeToSlide: true,
    touchThreshold: 100, // Increases swipe sensitivity
    afterChange: (index) => setActiveIndex(index),
    beforeChange: (current, next) => {
      if (current === projects.length - 1 && next === 0) {
        // When moving from last to first, skip to the second slide
        setTimeout(() => {
          setActiveIndex(1); // Go to second slide
        }, 300); // Adjust the timing as needed
      }
    },
    responsive: [
      {
        breakpoint: 1200, // When the screen width is <= 1199px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="portfolio-projects">
      <Section id="WEBDESIGNDEVELOPMENT" className="section" padding="30px">
        <div className="H2sProjects">
          <div className="m-cinzel-h2 h2-1WDD">WEB DESIGN &</div>
          <div className="m-herr-h2 h2-2WDD">Development</div>
        </div>
        <Slider {...settings}>
          {projects.map((project, index) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              description={project.description}
              backgroundImage={project.backgroundImage}
              isActive={index === activeIndex}
              caseStudyLink={project.caseStudyLink}
              figmaLink={project.figmaLink}
            />
          ))}
        </Slider>
      </Section>
    </div>
  );
};

export default PortfolioProjects;
