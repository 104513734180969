import React, { useState, useEffect } from "react";
import Section from "./Section";
import homeMobile from "../assets/images/homeMobile.png";
import homeTablet from "../assets/images/homeTablet.png";
import homeDesktop from "../assets/images/homeDesktop.png";
import "../styles/Home.scss";

function Home() {
  const [backgroundImage, setBackgroundImage] = useState(homeMobile);

  const updateBackgroundImage = () => {
    const width = window.innerWidth;
    if (width >= 1024) {
      setBackgroundImage(homeDesktop);
    } else if (width >= 768) {
      setBackgroundImage(homeTablet);
    } else {
      setBackgroundImage(homeMobile);
    }
  };

  useEffect(() => {
    // Set initial background image
    updateBackgroundImage();

    // Update background image on window resize
    window.addEventListener("resize", updateBackgroundImage);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", updateBackgroundImage);
    };
  }, []);

  return (
    <div className="Home">
      <Section
        id="HOME"
        className="section sectionInnerHome"
        backgroundImage={backgroundImage}
        padding="30px"
      >
        <div className="text">
          <div className="m-cinzel-home-decorative welcomeToMyDiv">Welcome to my</div>
          <div className="m-herr-home-decorative">Portfolio</div>
        </div>
      </Section>
    </div>
  );
}

export default Home;
