import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Section from "./Section";
import educationBckndMobile from "../assets/images/EducationBckgnd.png";
import "../styles/Education.scss";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";

// Set up PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;

function Education() {
  const [seeMoreIndex, setSeeMoreIndex] = useState(null);
  const [openSection, setOpenSection] = useState(null);
  const [activeButton, setActiveButton] = useState(null); // Track active button

  const [zoom, setZoom] = useState(1); // Default zoom level

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.2, 2)); // Max zoom level: 2
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.2, 0.5)); // Min zoom level: 0.5
  };

  const handlePrint = () => {
    const link = document.createElement("a");
    link.href = "/resume.pdf";
    link.target = "_blank";
    link.click();
    setTimeout(() => window.print(), 500);
  };

  const toggleSeeMore = (index) => {
    setSeeMoreIndex(seeMoreIndex === index ? null : index);
  };

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
    setActiveButton(section); // Set the active button
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    const link = document.createElement("a");
    link.href = "/resume.pdf";
    link.download = "resume.pdf";
    link.click();
  };

  const handlePreview = (e) => {
    e.stopPropagation();
    window.open("/resume.pdf", "_blank"); // Directly open PDF in a new tab
  };

  return (
    <div className="Education">
      <Section
        id="RESUME"
        className="section"
        backgroundImage={educationBckndMobile}
        padding="30px"
      >
        <div className="H2groupEducation">
          <div className="m-cinzel-h2">Education &</div>
          <div className="m-herr-h2">Resumé</div>
        </div>

        <div className="buttons">
          {/* RESUME Section */}
          <div className="topButton">
            <button
              className={`m-body resumeButton EdButton ${
                openSection === "resume" ? "active" : ""
              }`}
              onClick={() => toggleSection("resume")}
            >
              RESUME
            </button>

            <AnimatePresence>
              {openSection === "resume" && (
                <motion.div
                  className="dropdown-section resumeDropdown"
                  initial={{ height: 0, opacity: 0, overflow: "hidden" }}
                  animate={{ height: "auto", opacity: 1, overflow: "hidden" }}
                  exit={{ height: 0, opacity: 0, overflow: "hidden" }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="m-body paddingHere">RESUME</div>
                  <div className="openLinks paddingHere">
                    <div
                      className="m-caption-extra-light"
                      onClick={handleDownload}
                    >
                      DOWNLOAD
                    </div>
                    <div className="m-caption-regular" onClick={handlePreview}>
                      PREVIEW
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* EDUCATION Section */}
          <div className="bottomButton">
            <button
              className={`m-body educationButton EdButton ${
                openSection === "education" ? "active" : ""
              }`}
              onClick={() => toggleSection("education")}
            >
              EDUCATION
            </button>

            <AnimatePresence>
              {openSection === "education" && (
                <motion.div
                  className="dropdown-section educationDropdown"
                  style={{ maxHeight: "17rem", overflowY: "auto" }}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="BachelorsContainer paddingHereB">
                    <div className="m-cinzel-h4">BACHELORS OF SCIENCE</div>
                    <div className="containersIndividuals">
                      <div className="m-body">Web Design & Development</div>
                      <div className="m-caption-extra-light">
                        Brigham Young University - Idaho
                      </div>
                    </div>
                  </div>

                  <div className="CertificationsContainer paddingHereC">
                    <div className="m-cinzel-h4">CERTIFICATIONS</div>
                    <div className="containersIndividuals">
                      <div className="certification">
                        <div className="certificationTitle">
                          <div className="m-body">Web Design</div>
                          <div
                            className={`m-caption-extra-light seeMore ${
                              seeMoreIndex === 0 ? "active" : ""
                            }`}
                            onClick={() => toggleSeeMore(0)}
                          >
                            {seeMoreIndex === 0 ? "(see less)" : "(see more)"}
                          </div>
                        </div>

                        <AnimatePresence>
                          {seeMoreIndex === 0 && (
                            <motion.ul
                              className="m-body edUl"
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <li className="m-caption-extra-light">
                                Demonstrate skills in web design, HTML, CSS, and
                                JavaScript.
                              </li>
                              <li className="m-caption-extra-light">
                                Develop web pages that are semantic and valid
                                using the HTML5 markup language.
                              </li>
                              <li className="m-caption-extra-light">
                                Design web pages with valid Cascading Style
                                Sheets (CSS) level 3 and avoid all HTML
                                presentational markup.
                              </li>
                              <li className="m-caption-extra-light">
                                Provide visually appropriate, usable, and
                                findable content to both humans and machines.
                              </li>
                              <li className="m-caption-extra-light">
                                Utilize skills in planning, designing, and
                                developing web pages and sites according to best
                                practices of organization and maintainability.
                              </li>
                            </motion.ul>
                          )}
                        </AnimatePresence>
                      </div>

                      <div className="certification">
                        <div className="certificationTitle">
                          <div className="m-body">Database</div>
                          <div
                            className={`m-caption-extra-light seeMore ${
                              seeMoreIndex === 1 ? "active" : ""
                            }`}
                            onClick={() => toggleSeeMore(1)}
                          >
                            {seeMoreIndex === 1 ? "(see less)" : "(see more)"}
                          </div>
                        </div>

                        <AnimatePresence>
                          {seeMoreIndex === 1 && (
                            <motion.ul
                              className="m-body edUl"
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <li className="m-caption-extra-light">
                                Utilize SQL and Query to communicate with
                                databases.
                              </li>
                              <li className="m-caption-extra-light">
                                Design a transaction entity relationship model.
                              </li>
                              <li className="m-caption-extra-light">
                                Work with user-defined code objects in a
                                database.
                              </li>
                              <li className="m-caption-extra-light">
                                Provision flexible data environments aligning to
                                business initiatives.
                              </li>
                              <li className="m-caption-extra-light">
                                Demonstrate ability to work with big data and
                                supporting environments.
                              </li>
                            </motion.ul>
                          )}
                        </AnimatePresence>
                      </div>

                      <div className="certification">
                        <div className="certificationTitle">
                          <div className="m-body">TESOL</div>
                          <div
                            className={`m-caption-extra-light seeMore ${
                              seeMoreIndex === 2 ? "active" : ""
                            }`}
                            onClick={() => toggleSeeMore(2)}
                          >
                            {seeMoreIndex === 2 ? "(see less)" : "(see more)"}
                          </div>
                        </div>

                        <AnimatePresence>
                          {seeMoreIndex === 2 && (
                            <motion.ul
                              className="m-body edUl"
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <li className="m-caption-extra-light">
                                Apply skills in teaching English to
                                non-native-speaking learners.
                              </li>
                              <li className="m-caption-extra-light">
                                Develop curriculum and course material for
                                English learning programs.
                              </li>
                              <li className="m-caption-extra-light">
                                Analyze language acquisition and grammar
                                fundamentals for effective teaching.
                              </li>
                              <li className="m-caption-extra-light">
                                Plan, design, and deliver lessons that target
                                learning objectives.
                              </li>
                              <li className="m-caption-extra-light">
                                Assess student progress and provide feedback for
                                improvement.
                              </li>
                            </motion.ul>
                          )}
                        </AnimatePresence>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </Section>
    </div>
  );
}

export default Education;
