import React, { useState, useRef, useEffect } from "react"; // Import useState
import TestimonialCard from "./TestimonialCard";
import "../styles/Testimonials.scss";
import Section from "./Section";
import testimonialOne from "../assets/images/testimonialOne.png";
import testimonialTwo from "../assets/images/testimonialTwo.png";
import testimonialThree from "../assets/images/testimonialThree.png";
import testimonialFour from "../assets/images/testimonialFour.png";
import testimonialFive from "../assets/images/testimonialFive.png";

const testimonials = [
  {
    photo: testimonialOne,
    name: "John Doe",
    text: "A letter of recommendation is a valuable document that can make a significant difference in someone’s academic or professional journey. Whether you are a teacher, employer, colleague, or mentor, being asked to write a letter of recommendation is an honor and a responsibility. This article will guide you through the process of writing an effective and impactful letter of recommendation, helping you showcase the strengths and qualifications of the individual you are recommending!",
  },
  {
    photo: testimonialTwo,
    name: "Valerie Villalobos",
    text: "Before you begin writing, it’s essential to understand the purpose of the letter. Typically, letters of recommendation are requested for educational applications, job applications, scholarships, or other opportunities. The letter should provide insights into the candidate’s qualifications, skills, character, and suitability for the specific position or opportunity.",
  },
  {
    photo: testimonialThree,
    name: "David Miller",
    text: "When someone asks you to write a letter of recommendation, it’s crucial to request essential information from the candidate. This information can include: A copy of the candidate’s resume or CV. Details about the specific opportunity or program they are applying for. Information about their accomplishments, experiences, and goals. Any specific points they would like you to highlight.",
  },
  {
    photo: testimonialFour,
    name: "Hanna West",
    text: "By providing evidence of the candidate’s exceptional leadership, strategic thinking, work ethic, and problem-solving abilities, the writer effectively substantiates their claims and presents a comprehensive view of the candidate’s capabilities.",
  },
  {
    photo: testimonialFive,
    name: "Andrew Farnsworth",
    text: "By providing evidence of the candidate’s exceptional leadership, strategic thinking, work ethic, and problem-solving abilities, the writer effectively substantiates their claims and presents a comprehensive view of the candidate’s capabilities.",
  },
];

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollContainerRef = useRef(null);

  const handleDotClick = (index) => {
    setActiveIndex(index);
    scrollToCard(index);
  };

  const scrollToCard = (index) => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth * index;
      scrollContainerRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollLeft = scrollContainerRef.current.scrollLeft;
      const cardWidth = scrollContainerRef.current.clientWidth;
      const newIndex = Math.round(scrollLeft / cardWidth);
      setActiveIndex(newIndex);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="testimonials-section">
      <Section id="TESTIMONIALS" className="TestimonialsSection">
        <span className="testimonials-background-text testimonial-background">
          Testimonials
        </span>

        <div className="testimonialsContainer">
          <div
            className="testimonials-scroll-container"
            ref={scrollContainerRef}
          >
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                photo={testimonial.photo}
                name={testimonial.name}
                text={testimonial.text}
                className={index === activeIndex ? "active" : ""} // Correct conditional class
              />
            ))}
          </div>
          <div className="slider-dots">
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`dot ${index === activeIndex ? "active" : ""}`}
                onClick={() => handleDotClick(index)}
                aria-label={`Testimonial ${index + 1}`}
              ></button>
            ))}
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Testimonials;
