import React, { useState } from "react";
import Slider from "react-slick";
import ProjectCard from "./ProjectCard.js";
import Section from "./Section.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Projects.scss";
import imgOne from "../assets/images/Stickers.png";
import imgTwo from "../assets/images/watchReplica.png";
import imgThree from "../assets/images/gigPoster.png";

const projects = [
  {
    id: 1,
    title: "STICKERS",
    description:
      "Theme selected, sketches created, style chosen. Brought to life in Illustrator. Managed the printing process.",
    backgroundImage: imgOne,
    caseStudyLink: "https://example.com/spirit-of-wicks-case-study",
    figmaLink: "https://www.figma.com/file/spirit-of-wicks",
  },
  {
    id: 2,
    title: "WATCH REPLICA",
    description:
      "Meticulously replicated a watch photo in Illustrator. Focused on gradients and detailed techniques.",
    backgroundImage: imgTwo,
    caseStudyLink: "https://example.com/spirit-of-wicks-case-study",
    figmaLink: "https://www.figma.com/file/spirit-of-wicks",
  },
  {
    id: 3,
    title: "GIG POSTER",
    description:
      "Theme selected, sketches finalized in Illustrator & Photoshop. Created mockups.",
    backgroundImage: imgThree,
    caseStudyLink: "https://example.com/spirit-of-wicks-case-study",
    figmaLink: "https://www.figma.com/file/spirit-of-wicks",
  },

  // Add more projects as needed
];

const PortfolioProjects = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setActiveIndex(index), // Update activeIndex on slide change
  };

  return (
    <div className="portfolio-projects">
      <Section
        id="ADOBEILLUSTRATOR"
        className="section"
        // content="Section 2"
        padding="30px"
      >
        <div className="H2sProjects">
          <div className="m-cinzel-h2 h2-1AI">ADOBE</div>
          <div className="m-herr-h2 h2-2AI">Illustrator</div>
        </div>
        <Slider {...settings}>
          {projects.map((project, index) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              description={project.description}
              backgroundImage={project.backgroundImage}
              isActive={index === activeIndex}
              caseStudyLink={project.caseStudyLink}
              figmaLink={project.figmaLink}
            />
          ))}
        </Slider>
      </Section>
    </div>
  );
};

export default PortfolioProjects;
