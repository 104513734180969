// src/components/ImageSlider.js
import React, { useEffect, useState } from "react";
import "../styles/ImageSlider.scss"; // Import your CSS file for styling

const ImageSlider = ({ images, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState(images);

  useEffect(() => {
    const updateImagesBasedOnViewport = () => {
      // Logic to update images based on viewport size
      const width = window.innerWidth;
      if (width < 576) {
        setCurrentImages(images.map((img) => img.small));
      } else if (width < 992) {
        setCurrentImages(images.map((img) => img.medium));
      } else {
        setCurrentImages(images.map((img) => img.large));
      }
    };

    updateImagesBasedOnViewport();
    window.addEventListener("resize", updateImagesBasedOnViewport);

    const sliderInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
    }, interval);

    return () => {
      clearInterval(sliderInterval);
      window.removeEventListener("resize", updateImagesBasedOnViewport);
    };
  }, [images, interval, currentImages.length]);

  return (
    <div className="image-slider">
      <div
        className="image-slider-images"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {currentImages.map((image, index) => (
          <div
            key={index}
            className="image-slider-slide"
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
