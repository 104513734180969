// src/EducationCircles.js
import React from "react";
import Section from "./Section";
import TriadCircles from "./TriadCircles";
import "../styles/EducationCircle.scss";

function EducationCircles() {
  return (
    <Section id="EducationCircles" className="section" padding="30px">
      <div className="H2s-TriadCircles">
        <div className="H2groupEducationCircles">
          <div className="m-cinzel-h2">Portfolio</div>
          <div className="m-herr-h2">Projects</div>
        </div>
        <TriadCircles />
      </div>
    </Section>
  );
}

export default EducationCircles;
