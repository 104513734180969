import React, { useState } from "react";
import Section from "./Section";
import "../styles/GlobalFonts.scss";
import "../styles/Contact.scss";
import emailjs from "emailjs-com"; // Import emailjs
import checkmarkIcon from "../assets/icons/check-solid.svg"; // If needed for any other part

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading animation
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;

    if (!name || !email || !message) {
      setError("Please fill in all required fields.");
      return;
    }

    setError("");
    setLoading(true); // Start loading animation

    // Simulate loading for 3 seconds before showing the overlay
    setTimeout(() => {
      // Sending the email after the delay
      emailjs
        .send(
          "service_83c9ptn", // Replace with your EmailJS Service ID
          "template_cvgqfjo", // Replace with your EmailJS Template ID
          {
            from_name: name,
            from_email: email,
            message: message,
          },
          "F5qvPQq7GmiEiObww" // Replace with your EmailJS Public Key (User ID)
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setLoading(false); // Stop loading animation
            setFormSubmitted(true); // Show the confirmation overlay

            // Clear form *after* overlay is shown
            setFormData({
              name: "",
              email: "",
              message: "",
            });
          },
          (err) => {
            console.error("Failed to send email. Error: ", err);
            setError("Failed to send the message. Please try again.");
            setLoading(false); // Stop loading animation immediately on error
          }
        );
    }, 3000); // Keep loading animation visible for 3 seconds
  };

  const handleCloseOverlay = () => {
    setFormSubmitted(false);
  };

  return (
    <div className="Contact">
      <Section id="CONTACT" className="section" padding="30px">
        <div className="contactSection">
          <div className="H2group">
            <div className="top">
              <div className="m-cinzel-h2">Send me a</div>
            </div>
            <div className="bottom">
              <div className="m-herr-h2">message</div>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="inputBox">
              <input
                className="m-body inputBoxIndividual"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="NAME*"
                required
              />
            </div>
            <div className="inputBox">
              <input
                className="m-body inputBoxIndividual"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="EMAIL*"
                required
              />
            </div>
            <div className="inputBox">
              <textarea
                className="m-body inputBoxIndividual"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="MESSAGE*"
                required
              />
            </div>
            {error && <p className="error">{error}</p>}
<button 
  type="submit" 
  className={`submit-button ${loading ? 'loading' : ''}`} 
  disabled={loading}
>
  {loading ? (
    <div className="loading-animation">
      <div className="loader"></div>
    </div>
  ) : (
    "Send"
  )}
</button>

          </form>
        </div>

        {/* Confirmation Overlay */}
        {formSubmitted && (
          <div className="funds-success-overlay">
            <div className="funds-success-message-container">
              <div className="funds-checkmark-text-container">
                <div className="funds-checkmark-container">
                  <img
                    src={checkmarkIcon}
                    alt="Checkmark"
                    className="funds-checkmark"
                  />
                </div>

                <h1 className="funds-success-done-text">Done!</h1>
              </div>

              <div className="funds-success-message">
                <h2>Your message was sent.</h2>
                <p>
                  We appreciate your patience while we review and respond. If
                  you have any other questions, feel free to connect with me
                  on{" "}
                  <a
                    href="https://www.linkedin.com/in/cindycasttc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn{" "}
                  </a>
                  or
                  <a
                    href="https://www.behance.net/cindycastro4/projects"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Behance
                  </a>
                  .
                </p>
              </div>

              <button className="close-overlay" onClick={handleCloseOverlay}>
                &times; {/* Close icon */}
              </button>
            </div>
          </div>
        )}
      </Section>
    </div>
  );
}

export default ContactForm;
