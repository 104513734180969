import React from "react";
import "../styles/ProjectCard.scss";

const ProjectCard = ({ title, description, backgroundImage, isActive, caseStudyLink, figmaLink }) => {
  return (
    <div
      className={`project-card ${isActive ? "active" : ""}`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={`overlay ${isActive ? "fade-in" : ""}`}>
        <div className="projectCardTextAndButtons">
          <h2 className="m-cinzel-h3">{title}</h2>
          <p className="m-body">{description}</p>
          <p className="buttons">
            <a href={caseStudyLink} target="_blank" rel="noopener noreferrer">
              <button className="m-body">Case Study</button>
            </a>
            <a href={figmaLink} target="_blank" rel="noopener noreferrer">
              <button className="m-body">Figma Designs</button>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
