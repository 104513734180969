import React, { useState } from "react";
import Slider from "react-slick";
import ProjectCard from "./ProjectCard.js";
import Section from "./Section.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Projects.scss";
import imgOne from "../assets/images/familysearchImg.png";
import imgTwo from "../assets/images/additionalCollections.png";

const projects = [
  {
    id: 1,
    title: "familysearch®",
    description:
      "Designed flow, created Figma prototypes, conducted user testing, compiled and presented results.",
    backgroundImage: imgOne,
    caseStudyLink: "https://example.com/spirit-of-wicks-case-study",
    figmaLink: "https://www.figma.com/file/spirit-of-wicks",
  },
  {
    id: 2,
    title: "additional collections",
    description:
      "Redesigned interface, decluttered unnecessary elements. Collaborated with clients for insights, presented refined results.",
    backgroundImage: imgTwo,
    caseStudyLink: "https://example.com/spirit-of-wicks-case-study",
    figmaLink: "https://www.figma.com/file/spirit-of-wicks",
  },
  // Add more projects as needed
];

const PortfolioProjects = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleBeforeChange = (current, next) => {
    // Check if transitioning from last slide to first
    if (current === projects.length - 1 && next === 0) {
      console.log("Transitioning from last to first slide");
      // You can add custom logic here if needed
    }
  };

  // Use this in your Slider settings
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: handleBeforeChange, // Handle pre-transition logic
    afterChange: (index) => setActiveIndex(index), // Update activeIndex on slide change
  };

  return (
    <div className="portfolio-projects">
      <Section
        id="USEREXPERIENCE"
        className="section"
        // content="Section 2"
        padding="30px"
      >
        <div className="H2sProjects">
          <div className="m-cinzel-h2 h2-1UX">User</div>
          <div className="m-herr-h2 h2-2UX">Experience</div>
        </div>
        <Slider {...settings}>
          {projects.map((project, index) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              description={project.description}
              backgroundImage={project.backgroundImage}
              isActive={index === activeIndex}
              caseStudyLink={project.caseStudyLink}
              figmaLink={project.figmaLink}
            />
          ))}
        </Slider>
      </Section>
    </div>
  );
};

export default PortfolioProjects;
