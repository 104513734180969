import React, { useState } from "react";
import Slider from "react-slick";
import "../styles/TriadCircles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// PROJECTS IMAGES
import spiritOfWicks from "../assets/images/spiritOfWicks.png";
import carstro from "../assets/images/carstro.png";
import familySearch from "../assets/images/familySearch.png";
import myPortfolio from "../assets/images/myPortfolio.png";
import additionalCollections from "../assets/images/additionalCollections.png";
import uxFamilySearch from "../assets/images/uxFamilySearch.png";
import watchReplica from "../assets/images/watchReplica.png";
import gigPoster from "../assets/images/gigPoster.png";
import Stickers from "../assets/images/Stickers.png";

const TriadCircles = () => {
  const [overlay, setOverlay] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0); // Track the current active slide

  const handleCircleClick = (index) => {
    setOverlay(index);
    setCurrentSlide(0); // Reset to the first slide whenever a circle is clicked
  };

  const handleCloseOverlay = () => {
    setOverlay(null);
  };

  // Define the text for each circle
  const circleTexts = [
    "WEB DESIGN & DEVELOPMENT",
    "USER EXPERIENCE",
    "ADOBE ILLUSTRATOR",
  ];

  // Example projects for each circle (change for different bundles)
  const projectBundles = [
    [
      {
        id: 1,
        title: "THE SPIRIT OF WICKS",
        description:
          "Developed a fully responsive site using Wix. Shoot, selected, and edited each photo; created mockups using Photoshop.",
        image: spiritOfWicks,
      },
      {
        id: 2,
        title: "CARSTRO",
        description:
          "Co-designed & developed. Solely responsible for logo, video, and body copy. Removed backgrounds from, and optimized, car images. Design Tools: Figma, Photoshop, Premiere Pro, Developing Tools: ReactJS, CSS3. Fully responsive.",
        image: carstro,
      },
      {
        id: 3,
        title: "MY PORTFOLIO",
        description:
          "Designed & developed from scratch. All visuals created. Fully responsive. Tools used: Figma, Photoshop, ReactJS, SCSS.",
        image: myPortfolio,
      },
      {
        id: 4,
        title: "FAMILYSEARCH®",
        description:
          "Co-designed & developed. Solely responsible for logo, video, and body copy. Removed backgrounds from, and optimized, car images. Design Tools: Figma, Photoshop, Premiere Pro, Developing Tools: ReactJS, CSS3. Fully responsive.",
        image: familySearch,
      },
    ],
    [
      {
        id: 1,
        title: "FAMILYSEARCH®",
        description:
          "Designed flow, created Figma prototypes, conducted user testing, compiled and presented results.",
        image: uxFamilySearch,
      },
      {
        id: 2,
        title: "ADDITIONAL COLLECTIONS",
        description:
          "Redesigned interface, decluttered unnecessary elements. Collaborated with clients for insights, presented refined results.",
        image: additionalCollections,
      },
    ],
    [
      {
        id: 1,
        title: "STICKERS",
        description:
          "Theme selected, sketches created, style chosen. Brought to life in Illustrator. Managed the printing process.",
        image: Stickers,
      },
      {
        id: 2,
        title: "WATCH REPLICA",
        description:
          "Meticulously replicated a watch photo in Illustrator. Focused on gradients and detailed techniques.",
        image: watchReplica,
      },
      {
        id: 3,
        title: "GIG POSTER",
        description:
          "Theme selected, sketches finalized in Illustrator & Photoshop. Created mockups.",
        image: gigPoster,
      },
    ],
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true, // Enables fading transition
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current), // Update the active slide index
  };

  return (
    <div className="triad-circles">
      {circleTexts.map((text, index) => (
        <div
          key={index}
          className={`circle m-body circle-${index + 1}`}
          onClick={() => handleCircleClick(index)}
        >
          <span>{text}</span>
        </div>
      ))}

      {overlay !== null && (
        <div className="overlay">
          <div className="overlay-background">
            <div
              className="the-background-image"
              style={{
                backgroundImage: `url(${projectBundles[overlay][currentSlide].image})`,
              }}
              
            />
            <div className="overlay-darken" />
          </div>
          <div className="overlay-content">
            <Slider {...sliderSettings}>
              {projectBundles[overlay].map((project) => (
                <div key={project.id} className="project-slide">
                  <div className="m-cinzel-h3">{project.title}</div>
                  <p>{project.description}</p>
                </div>
              ))}
            </Slider>
<div className="ContentWrapper">
            <button
              className="close-button m-cinzel-h5"
              onClick={handleCloseOverlay}
            >
              CLOSE
            </button>

            <div className="overlay-text">
              <div className="m-cinzel-h3 projectTitle">
                {projectBundles[overlay][currentSlide].title}
              </div>
              <div className="m-body projectDescription">
                {projectBundles[overlay][currentSlide].description}
              </div>
              <div className="projectCardButtons">
                <a
                  href="your-case-study-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="m-body TriadCirclesButtons">
                    Case Study
                  </button>
                </a>
                <a
                  href="your-live-project-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="m-body">See Live</button>
                </a>
              </div>
            </div>

            </div>




          </div>
        </div>
      )}
    </div>
  );
};

export default TriadCircles;