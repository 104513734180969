import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import ScrollableContainer from "./components/ScrollableContainer";
import BottomNavigation from "./components/BottomNavigation.js";
import SideNav from "./components/SideNav.js";
import Home from "./components/Home.js";
import ProjectsUX from "./components/ProjectsUX.js";
import ProjectsWDD from "./components/ProjectsWDD.js";
import ProjectsAdobeIllustrator from "./components/ProjectsAdobeIllustrator.js";
import Education from "./components/Education.js";
import EducationCircles from "./components/EducationCircles.js";
import Testimonials from "./components/Testimonials.js";
import About from "./components/About.js";
import Contact from "./components/Contact.js";
import Footer from "./components/Footer.js";
import "../src/components/Firebase.js"; // Import the Firebase configuration

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SideNav />

    <div className="indexDiv MandL">
      <ScrollableContainer className="ScrollableContainer">
        <Home />
        <EducationCircles />
        <ProjectsWDD />
        <ProjectsUX />
        <ProjectsAdobeIllustrator />
        <Education />
        <Testimonials />
        <About />
        <Contact />
        <Footer />
      </ScrollableContainer>
    </div>

    <div className="indexDivSmall">
        <Home />
        {/* <EducationCircles /> */}
        <ProjectsWDD />
        <ProjectsUX />
        <ProjectsAdobeIllustrator />
        <Education />
        <Testimonials />
        <About />
        <Contact />
        <Footer />
    </div>
  </React.StrictMode>
);
