import React from "react";
import "../styles/Footer.scss";
import behance from "../assets/icons/Behance.svg";
import linkedin from "../assets/icons/Linkedin.svg";

function Footer() {
  return (
    <div className="Footer">
      <div className="m-herr-h3 footerText">Let's Stay connected!</div>
      <div className="foooter-icons">
        {/* Behance Icon */}
        <a
          href="https://www.behance.net/cindycastro4/projects" // Replace with your Behance URL
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={behance} className="socialMediaIcon Behance" alt="Behance Icon" />
        </a>

        {/* LinkedIn Icon */}
        <a
          href="https://www.linkedin.com/in/cindycasttc/en" // Replace with your LinkedIn URL
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedin} className="socialMediaIcon Linkedin" alt="LinkedIn Icon" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
