import React from "react";
import "../styles/TestimonialCard.scss";

const TestimonialCard = ({ photo, name, text, className }) => {
  return (
    <div className={`testimonial-card ${className}`}>
      <img src={photo} alt={name} className="testimonial-photo" />
      <div className="m-cinzel-h3 testimonialName">{name}</div>
      <div className="m-body">{text}</div>
    </div>
  );
};


export default TestimonialCard;
