import React, { useEffect, useState } from "react";
import Section from "./Section";
import "../styles/SideNav.scss";
import behance from "../assets/icons/Behance.svg";
import linkedin from "../assets/icons/Linkedin.svg";

function SideNav() {
  const [activeSection, setActiveSection] = useState(""); // State for active section

  const handleScroll = (sectionId) => {
    console.log(`Scrolling to section: ${sectionId}`); // Logging the section to scroll to
  
    // Log all section elements in the DOM; changed to query for divs
    const allSections = document.querySelectorAll("div.section");
    console.log("All sections in the DOM:", allSections);
  
    // Check if sections are found
    allSections.forEach(section => {
      console.log(`Section ID: ${section.id}`);
    });
  
    // Attempt to find the section by id
    const section = document.getElementById(sectionId);
    if (section) {
      console.log(`Found section with id '${sectionId}'. Scrolling now...`); // Scrolling to section
      section.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error(`Section with id '${sectionId}' not found.`); // Error if not found
    }
  };

  useEffect(() => {
    const handleScrollEvent = () => {
      const sections = document.querySelectorAll("div.section"); // Changed to divs
      let currentSection = "";

      // Loop through sections and find the one in the viewport
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        // Check if the section is in view (adjust for your specific layout)
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          currentSection = section.id;
        }
      });

      // If a section is detected, set it as active
      if (currentSection !== activeSection) {
        console.log(`Currently viewing section: ${currentSection}`);
        setActiveSection(currentSection);
      }
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScrollEvent);

    // Clean up listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, [activeSection]);

  return (
    <div className="SideNav">
      <Section
        id="SideNav"
        className="section SideNav"
        padding="30px"
      >
        <div className="SideNavTopContent">
          <div className="sideNavMyName" onClick={() => handleScroll("HOME")}>
            <div className="m-cinzel-h3">CINDY</div>
            <div className="m-cinzel-h3">CASTRO</div>
          </div>
          <div className="SideNavMiddleContent">
            <ul className="SideNavUl">
              <li
                className={`m-body nav-item ${activeSection === "WEBDESIGN&DEVELOPMENT" ? "active" : ""}`}
                onClick={() => handleScroll("WEBDESIGNDEVELOPMENT")}
              >
                Web Design
              </li>
              <li
                className={`m-body nav-item ${activeSection === "USEREXPERIENCE" ? "active" : ""}`}
                onClick={() => handleScroll("USEREXPERIENCE")}
              >
                User Experience
              </li>
              <li
                className={`m-body nav-item ${activeSection === "ADOBEILLUSTRATOR" ? "active" : ""}`}
                onClick={() => handleScroll("ADOBEILLUSTRATOR")}
              >
                Adobe Illustrator
              </li>
              <li
                className={`m-body nav-item ${activeSection === "RESUME" ? "active" : ""}`}
                onClick={() => handleScroll("RESUME")}
              >
                Resume
              </li>
              <li
                className={`m-body nav-item ${activeSection === "TESTIMONIALS" ? "active" : ""}`}
                onClick={() => handleScroll("TESTIMONIALS")}
              >
                Testimonials
              </li>
              <li
                className={`m-body nav-item ${activeSection === "ABOUT" ? "active" : ""}`}
                onClick={() => handleScroll("ABOUT")}
              >
                About
              </li>
              <li
                className={`m-body nav-item ${activeSection === "CONTACT" ? "active" : ""}`}
                onClick={() => handleScroll("CONTACT")}
              >
                Contact
              </li>
            </ul>
          </div>
        </div>

        <div className="SideNavBottomContent">
          <div className="m-herr-h3 footerText">-</div>
          <div className="footer-icons">
            <a
              href="https://www.behance.net/cindycastro4/projects"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={behance}
                className="socialMediaIcon Behance"
                alt="Behance Icon"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/cindycasttc/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedin}
                className="socialMediaIcon Linkedin"
                alt="LinkedIn Icon"
              />
            </a>
          </div>
        </div>
      </Section>
    </div>
  );
}

export default SideNav;
